@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

.bg-grey-tint {
    background-color: rgba(0, 0, 0, 0.25);
}